import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BlogDetailsPage from "../../components/body/pages/blog/blog-details"
const BlogDetailsPageByParam = (props) => {
    const blog = props.pageContext?.blog ? props.pageContext?.blog: props.location?.state?.blog
    return (
        <Layout>
            <SEO
                canonical={`${blog ? `https://kuda.com${blog?.blogUrl}` : "https://kuda.com/404/"}`}
                title={blog?.seoTitle}
                description={blog?.seoDescription}
                noIndex={!blog || process.env.GATSBY_ENV !== 'Prod' ? true : false}
            />
            <BlogDetailsPage blogDetails={blog}/>
        </Layout>
    )
}

export default BlogDetailsPageByParam
